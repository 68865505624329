<template>
    <vs-card class="mt-0 mb-0">     
        <div slot="header">
          
          <h4 class="font-weight-bold card-title d-flex"><vs-icon icon="drag_handle" class="mr-2" v-show="Editable"></vs-icon>
            <span v-show="!IsDeliveryAuto">{{$t('ACCOUNTING.ACC_MEMBER.HEADER') + ' ' + PosNr}}</span>
            <span v-show="IsDeliveryAuto">{{$t('ACCOUNTING.ACC_MEMBER.DELIVERY')}}</span>
            <div class="ml-auto text-right btn-alignment" v-show="Editable">
              <vs-button v-show="!IsMemberAutoRAL && !IsDeliveryAuto" size="small" color="primary" type="filled" icon="edit" @click="$emit('EditClicked',act_member)" ></vs-button>  
              <vs-button v-show="!IsMemberAutoRAL && !IsDeliveryAuto" size="small" color="primary" type="filled" icon="delete" @click="OpenConfirmDelete(act_member.id,$t('ACCOUNTING.ACC_MEMBER.HEADER') + ' ' + PosNr)"></vs-button>            
            </div> 
          </h4>
        </div>
        <div v-show="!act_member.text_only && !IsDeliveryAuto" >
          <div class="d-flex">
            <vs-list class="width-50p">
              <vs-list-item :title="act_member.product_bez" :subtitle="GetProductTextLine2()"></vs-list-item>
              <template v-for="(variant,index) in act_member.productVariants">
                <vs-list-item :key="'var_'+index" :title="GetVariantGroupText(variant.product_variant_group_fk)" :subtitle="GetVariantText(variant.product_variant_fk)"></vs-list-item>
              </template>
              <vs-list-item v-show="showAnmerkung" :title="$t('ACCOUNTING.ACC_MEMBER.ANMERKUNG')" :subtitle="act_member.text"></vs-list-item>
            </vs-list>
            <vs-list class="width-49p">
              <vs-list-item :title="$t('ACCOUNTING.ACC_MEMBER.PRICE')" :subtitle="$t('ACCOUNTING.ACC_MEMBER.MWST_1')+act_member.mwst_val+$t('ACCOUNTING.ACC_MEMBER.MWST_2')"><vs-chip>{{NettoText + ' | ' + BruttoText}}</vs-chip></vs-list-item>
              <vs-list-item v-show="showSecondLine" :title="$t('ACCOUNTING.ACC_MEMBER.AMOUNT')"  :subtitle="act_member.amount + ' x ' + BruttoText">
                <vs-input-number v-show="Editable" v-model="amount" size="small" min="1" class="inputx"/>
                <vs-chip v-show="!Editable">{{amount}}</vs-chip>
              </vs-list-item>
              <vs-list-item v-show="act_member.partnerdiscount_val > 0" :title="$t('ACCOUNTING.ACC_MEMBER.PARTNER_DISCOUNT')"  :subtitle="act_member.partnerdiscount_val+'%'">
                <vs-chip >{{PartnerDiscountText}}</vs-chip>
              </vs-list-item>
            </vs-list>
          </div>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <div class="btn-alignment">
                <vs-chip  color="primary" class="font-size-120p">{{GesBruttoText}}</vs-chip>
              </div>
            </vs-row>
          </div>
        </div>
        <div v-show="IsDeliveryAuto" >
          <div class="d-flex">
            <p>{{act_member.product_bez}}</p>
          </div>
           <div slot="footer">
             <vs-row vs-justify="flex-end">
              <div class="btn-alignment">
                <vs-chip  color="primary" class="font-size-120p">{{GesBruttoText}}</vs-chip>
              </div>
            </vs-row>
           </div>
        </div>
        <div v-show="act_member.text_only">
          <p>{{act_member.text}}</p>
        </div>
      </vs-card>
</template>
<script>

import helperMethods from '../../helper/staticFuncHelper';
import codeHelper from '../../helper/staticCodeHelper';
import enumHelper from '../../helper/enumHelper';
import {autoMemberType} from '../../helper/enumHelper';
import {productVariantExtraOptions} from '../../helper/enumHelper';

export default {
  name: "AccountingMember",
  props:['Member','PosNr','Pricelist','Editable','isOffer'],
  data: function (){
     return {
      id:0,
      act_member:this.Member,
      amount:this.Member.amount
     }
  }, 
  mounted () {
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      CalcGesamtBrutto()
      {
          this.act_member.sum_brutto = this.CalcBrutto(this.act_member.mwst_val, this.ges_netto);
      },
      OpenConfirmDelete(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('ACCOUNTING.ACC_MEMBER.QUESTION.DELETE').replace("*1*",bez),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        // Delete Request außerhalb abfeuern, sonst wird das element entfernt bevor es
        // die notification absenden kann
        this.$emit('DeleteMember',parameters);
      },
      SaveAmount()
      {
        var name = this.$t('ACCOUNTING.ACC_MEMBER.HEADER') + ' ' + this.PosNr;
        var service = 'accounting/saveOfferMemberAmount';
        if(this.isOffer == false)
        {
          service = 'partnerorder/savePartnerOrderMember';
        }

        this.$store.dispatch(service, this.act_member)
              .then(response => {
                if(response.success == true)
                {
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('OFFERS.OFFERMEMBER.SUCCESS.SAVE').replace("*1*",name)});  
                }
                else
                {
                  var text = this.$t('OFFERS.OFFERMEMBER.WARNING.SAVE').replace("*1*",name);
                  text = text.replace("*2*",this.$t(response.text));
                  this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
                }
                this.$emit('Saved');
              }, 
              error =>{
                  var text = this.$t('OFFERS.OFFERMEMBER.ERROR.SAVE').replace("*1*",name);
                  text = text.replace("*2*",error);
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
                  this.$emit('Saved');
              });
      },
      GetProductTextLine2(){
        var text = this.act_member.article_nr;
        
        if(this.act_member.automembertype == autoMemberType.RALCOLORSTD || this.act_member.automembertype == autoMemberType.RALCOLORNEW)
        {
          text += ', '+ this.act_member.surface_txt;
        }
        return text;
      },
      GetVariantGroupText(id)
      {
        var text = "";

        var grp = this.GetProductVariantGroup(id);
        if(this.IsNotNull(grp))
        {
          text = grp.label;
        }

        return text;
      },
      GetVariantText(id)
      {
        var text = "";

        var variant = this.GetProductVariant(id);
        if(this.IsNotNull(variant))
        {
          text = variant.label_long;

          if(variant.extraoption == productVariantExtraOptions.RALPICKER)
          {
            var color = this.GetRalById(this.act_member.ral_fk);
            if(this.IsNotNull(color))
            {
              text += ', ' + color.ral +' ' +this.$t('RALCOLOR.'+color.ral.replace(' ','_'));
            }
          }

          if(variant.extraoption == productVariantExtraOptions.MOTIFPICKER)
          {
            var motif = this.GetMotifById(this.act_member.motif_fk);
            if(this.IsNotNull(motif))
            {
              text += ', ' + motif.name + ', ' + this.GetOrientationText(this.act_member.motif_orientation);
            }
          }
        }

        return text;
      },
  },
  computed:{
    NettoText(){    
       return this.DecimalToString(this.act_member.netto,this.act_member.currency_kuerzel,this.GetLocalFormatOfLanguage(this.act_member.language_fk));
    },
    BruttoText(){
      return this.DecimalToString(this.act_member.brutto,this.act_member.currency_kuerzel,this.GetLocalFormatOfLanguage(this.act_member.language_fk));
    },
    GesBruttoText(){    
        this.CalcGesamtBrutto();
        return this.DecimalToString(this.act_member.sum_brutto - this.act_member.partnerdiscount_brutto,this.act_member.currency_kuerzel,this.GetLocalFormatOfLanguage(this.act_member.language_fk));
    },
    PartnerDiscountText(){
      return this.DecimalToString(this.act_member.partnerdiscount_brutto * (-1),this.act_member.currency_kuerzel,this.GetLocalFormatOfLanguage(this.act_member.language_fk));
    },
    ges_netto(){
      return this.act_member.netto * this.act_member.amount;
    },
    act_amount(){
      return this.act_member.amount;
    },
    showSecondLine(){
      return !this.IsMemberAutoRAL;
    },
    showAnmerkung(){
      var retVal = false;
      if(this.IsNotNull(this.act_member.text))
      {
        retVal = this.act_member.text.length > 0;
      }
      return retVal;
    },
    IsMemberAutoRAL(){
       return this.act_member.automembertype == autoMemberType.RALCOLORSTD || this.act_member.automembertype == autoMemberType.RALCOLORNEW;
    },
    IsDeliveryAuto(){
      return this.act_member.automembertype == autoMemberType.DELIVERYCONDITION;
    }
  },
  watch:{
    ges_netto(value)
    {
        this.act_member.sum_netto = value;
    },
    act_amount(){
      this.SaveAmount();
    },
    amount(value)
    {
      this.act_member.amount = Number(value);
    }
  }
};
</script>